import * as React from 'react'
import { Link } from 'gatsby'
import { SocialLinks } from '../../components/social-icons'

import { FaHome } from 'react-icons/fa'
import { Fade } from 'react-awesome-reveal'

import Seo from '../../components/seo'
import vid from '../../videos/404.mp4'

const Site404 = () => (
	<>
		<Seo title="Page not found" />
		<video autoPlay muted loop className="bg-video">
			<source src={vid} type="video/mp4" />
		</video>
		<div className="hero h-screen">
			<div className="hero-overlay bg-opacity-80"></div>
			<div className="text-center min-h-screen bg-opacity-50 sm:bg-opacity-95 absolute bg-base-300 left-0 top-0 flex items-center">
				<div className="max-w-2xl p-10 text-neutral-content">
					<Fade damping=".25" triggerOnce="true" cascade>
						<h1 className="mb-5 text-4xl md:text-6xl font-bold">
							Page not found!
						</h1>
						<p className="mb-5 text-xl py-4">
							Always remember, Frodo, the page is trying to get back to its
							master. It wants to be found.
						</p>
						<Link to="/" className="btn btn-lg btn-wide btn-primary">
							<FaHome className="pr-2 align-middle" size={25} />
							<span>Home</span>
						</Link>
						<div className="mx-auto w-full absolute left-0 bottom-10">
							<div className="flex sm:w-6/12 justify-evenly mx-auto gap-2">
								<SocialLinks></SocialLinks>
							</div>
						</div>
					</Fade>
				</div>
			</div>
		</div>
	</>
)

export default Site404
